export interface GetAgentInfoResponse {
  data: AgentResponseData;
}

export interface AgentResponseData {
  are_agents_online: number | boolean;
  num_customers_in_queue: number;
  agents_available_now: number;
  agents: AgentLists[];
  estimated_start_time: number;
  tenant_id: number;
  default_queue_id: number;
  booking_url: string;
  queues: QueueData[];
  booking_config: BookingConfig;
  tenant_name?: string;
  chat_expanded_default?: boolean | number;
  fab_config: FabConfig;
  join_queue_config: null | any;
  outbound_config?: OutboundConfig;
  initial_pop_up_delay?: number;
  language_config?: LanguageConfig
}

export interface OutboundConfig {
  cobrowse_licensekey: string;
  observe_allowed: number;
}

export interface FabConfig {
  fab_hide: boolean;
  blacklist?: string[];
  whitelist?: string[];
  colors?: { name: string, value: string }[];
  fab_image: string;
  default_language: string;
  disable_send_feedback?: 0 | 1;
  fab_caption?: FabTitle;
  nudges: NudgesTypes;
  fab_config_version: number;
  nudge_config_version: number;
  position_config: PluginPosition;
  fab_border_color?: string;
}

export interface NudgesTypes {
  mobile?: Nudge[];
  desktop?: Nudge[];
  tablet?: Nudge[];
}

export interface PluginPosition {
  position: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

export enum Positions {
  top_left = 'top-left',
  top_right = 'top-right',
  bottom_left = 'bottom-left',
  bottom_right = 'bottom-right'
}

export interface FabTitle {
  [key: string] : string;
}

export interface Nudge {
  appear_delay: number;
  duration_on_screen: number;
  whitelist: string[];
  blacklist: string[];
  content: Content;
  action: string;
  nudge_category?: string;
  position_config: PluginPosition;
}

export interface Content {
  [key: string] :NudgeContent;
}

export interface NudgeContent {
  content: string;
  width: number;
  height: number;
  version: number;
  nudge_identifier: string;
}

export interface BookingConfig {
  defaults: DefaultsConfig;
  daily: DailyBookingDays;
  custom_form?: any;
}

interface DailyBookingKeys {
  [key: string]: Exceptions[] | any;
}

export interface DailyBookingDays extends DailyBookingKeys {
  monday: Exceptions[] | any;
  tuesday: Exceptions[] | any;
  wednesday: Exceptions[] | any;
  thursday: Exceptions[] | any;
  friday: Exceptions[] | any;
  saturday: Exceptions[] | any;
  sunday: Exceptions[] | any;
  exceptions: Exceptions[] | any;
  min_days_in_advance?: number;
}

export interface Exceptions {
  date?: string;
  start?: null | string;
  end?: null | string;
  interval?: number;
}

export interface DefaultsConfig {
  slots: number;
  lead_time_mins: number;
  lead_time_days: number;
  interval: number;
  duration: number;
  timezone: string;
  disabled?: number | null;
  leave_message_disabled: number | null;
  country_code?: string;
}

export interface QueueData {
  queue_id: number;
  queue_name: string;
}

export interface AgentLists {
  agent_first_name: string;
  agent_status: string;
  agent_avatar: string | null;
  agentShortName? : string;
  queue_id: number[];
}

export enum AgentStatus {
  status = 'agent_status',
  dismissed = 'dismissed',
  agentStatusInfo = 'Agent_Status_Info',
  smallPopUp = 'small_pop_up',
  dismissedNudges =  'dismissedNudges',
  agentStatusCacheData = 'agent_status_cache_data'
}

export interface AgentStatusUpdateSocket {
  tenant_id: number[];
}

export interface Locale {
  [key: string]: string;
}

export interface LanguageConfig {
  en?: Locale;
  fr?: Locale;
}

export interface LangObjStructure {
  [key: string]: LangObj;
}
export interface LangObj{
  [key: string]: string | Locale;
}

