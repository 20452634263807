import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NudgeContent } from '../../core/constants/common.enum';
import { UtilsService } from '../../core/services/utils/utils.service';
import { AgentStatus, Nudge } from '../../core/constants/agent.modal';

@Component({
  selector: 'app-nudge-text',
  templateUrl: './nudge-text.component.html',
  styleUrls: ['./nudge-text.component.scss']
})
export class NudgeTextComponent implements AfterViewInit {
  @Input() nudgeContent: SafeHtml;
  @Input() currentNudge: Nudge;
  @Input() language: string;
  @Output() nudgeClickEvent = new EventEmitter();

  constructor(private utils: UtilsService,
              private elementRef:ElementRef) {}

  ngAfterViewInit() {
    const element = this.elementRef.nativeElement.getElementsByClassName('nudge-text');
    if(element[0]){
      element[0].addEventListener('click', this.onClick.bind(this));
    }
  }

  onClick() {
    const agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    if(agentDetails?.are_agents_online || agentDetails?.join_queue_config?.routing_enabled){
      this.utils.setSessionVal([NudgeContent.htmlContent, NudgeContent.isNudgeClick, NudgeContent.nudgeCategory],
          [this.currentNudge.content[this.language]?.content, true, this.currentNudge?.nudge_category]);
    }
  }

  nudgeClick(){
    this.nudgeClickEvent.emit(true);
  }

}
