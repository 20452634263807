<div class="user-form op-px-4 op-py-3">
    <div class="user-form-header op-d-flex op-justify-content-between op-align-items-center op-mb-3">
        <h5 class="app-title-text op-m-0">
            <a [innerHTML]="'common.liveVideoSupport' | translate"></a>
        </h5>
        <button (click)="close()"
            class="op-btn op-btn-icon op-px-0 op-py-0 pointer op-d-flex op-align-items-center">
            <svg-icon name="close" class="close-icon op-d-flex"></svg-icon>
        </button>
    </div>
  <div *ngIf="showIframe">
    <app-iframe-config [configData]="customConfigData"
                       [btnValue]= "btnValue"
                       [roomInfo]="roomInfo"
                       [allInputFields]="allInputFields"
                       [formVersion]="genericFormVersion"
                       (submitIFrameFormEvent)="onSubmitIFrameForm($event)"></app-iframe-config>
  </div>
    <form [formGroup]="userForm" *ngIf="!showIframe" class="op-mb-3" #userInfo>
        <p class="font-large title op-mb-3 op-mt-0"
           [innerHTML]=" (roomInfo?.roomName ? 'userForm.joinOngoingCall' :
            'userForm.connectWithAgent' )| translate"></p>
        <div *ngFor="let item of allFormControls; let i=index" class="op-form-group op-mb-2">
          <input type="text" class="op-form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
                 formControlName="{{item.name}}" [ngClass]="{ 'is-invalid' : getFormControlInfo(item.name)}" />
          <div class="op-error op-d-flex op-align-items-center op-mb-2 op-mt-2" *ngIf="getFormControlInfo(item.name)">
              <i class="warning op-mr-1"></i><span [innerHTML]="getError(item.name)"></span>
          </div>
        </div>
        <div class="op-form-group op-mb-2" *ngIf="agentDetails?.queues?.length">
            <select formControlName="queue_id" class="op-form-control" [ngClass]="{ 'is-invalid' : getFormControlInfo('queue_id')}">
                <option value="" default hidden [innerHTML]="'userForm.categoryRequired' | translate"></option>
                <option *ngFor="let queue of agentDetails?.queues" [value]="queue.queue_id"
                        class="op-d-flex op-justify-content-center op-align-items-center">{{queue?.queue_name}}</option>
            </select>
            <div class="op-error d-flex align-items-center op-mb-2 op-mt-2" *ngIf="getFormControlInfo('queue_id')">
                <i class="warning op-mr-1"></i><span [innerHTML]="'userForm.categoryError' | translate"></span>
            </div>
        </div>
    </form>
    <div class="terms-policy">
        <span [innerHTML]="'userForm.agreementStart' | translate"> </span>
        <a href="{{constants.privacyPolicy}}" class="policy" target="_blank" [innerHTML]="'userForm.policy' | translate"
           appAddSnowplowEvent [category]="withInvite ? spTrackerLabels.inviteLink : spTrackerLabels.inboundForm "
           [action]="spTrackerLabels.privacyPolicy" [label]="showIframe ? spTrackerLabels.customForm : spTrackerLabels.genericForm"></a>
        <span class="ml-1" [innerHTML]="'userForm.agreementEnd' | translate"></span>
    </div>
    <div class="op-d-flex op-justify-content-center" *ngIf="!showIframe">
        <div class="op-d-flex op-flex-column op-align-items-center op-w-100 op-mt-3">
            <button type="submit" appAddSnowplowEvent [category]="withInvite ? spTrackerLabels.inviteLink : spTrackerLabels.inboundForm "
                    [action]="withInvite ? spTrackerLabels.joinCall : spTrackerLabels.joinQueue" [label]="spTrackerLabels.genericForm" [innerHTML]="'userForm.joinCall' | translate"
                    class="op-btn op-btn-primary op-w-100 submit" (click)="onSubmit()"></button>
        </div>
    </div>
</div>
