import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// @ts-ignore
import io from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { AgentStatusUpdateSocket } from '../../constants/agent.modal';
import {
  CobrowseInfo,
  OutboundAcceptRes,
  OutboundInfo,
  RoomCloseData,
  RoomCreatedData,
  RoomData,
  MemberUpdatedSocket
} from '../../constants/common.enum';
import { ConversationWebHookMessage, TypingInfo } from '../../constants/chat.modal';

const socket = io(environment.baseApiUrl, {transports: ['websocket']});

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public agentStatusUpdateSocket = new Subject<AgentStatusUpdateSocket>();
  public messageData = new Subject<ConversationWebHookMessage>();
  public memberUpdatedSocket = new Subject<MemberUpdatedSocket>();
  public roomData = new Subject<RoomData>();
  public roomClose = new Subject<RoomCloseData>();
  public roomCreated = new Subject<RoomCreatedData>();
  public outboundInitiated = new Subject<OutboundInfo>();
  public outboundAccepted = new Subject<OutboundAcceptRes>();
  public cobrowseInitiated = new Subject<CobrowseInfo>();
  public typingInfo = new Subject<TypingInfo>();
  public reJoinRooms = new Subject<boolean>();

  constructor() {
    socket.on('connect', () => {
      this.reJoinRooms.next(true);
    });

    socket.on('agentStatusUpdate', (res: AgentStatusUpdateSocket) => {
      this.agentStatusUpdateSocket.next(res);
    });

    socket.on('cobrowseUpdate', (res: CobrowseInfo) => {
      this.cobrowseInitiated.next(res);
    });

    socket.on('outboundInitiated', (res: OutboundInfo) => {
      this.outboundInitiated.next(res);
    });

    socket.on('memberUpdated', (res: MemberUpdatedSocket) => {
      this.memberUpdatedSocket.next(res);
    });

    socket.on('sms', (res: ConversationWebHookMessage) => {
      this.messageData.next(res);
    });

    socket.on('roomClosed', (res: RoomCloseData) => {
      this.roomClose.next(res);
    });

    socket.on('roomCreated', (res: RoomCreatedData) => {
      this.roomCreated.next(res);
    });

    socket.on('roomInfo', (res: RoomData) => {
      console.log('roomInfo',res);
      this.roomData.next(res);
    });


    socket.on('outboundAccepted', (res: OutboundAcceptRes) => {
      this.outboundAccepted.next(res);
    });

    socket.on('typing', (res: TypingInfo) => {
      this.typingInfo.next(res);
    });
  }

  joinRoom(room: string | string[]) {
    socket.emit('join_room', room, (response: any) => {
      console.log('joined room', response);
    });
  }
}
