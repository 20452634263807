import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageOptions, RoutesUrls, OminiReq } from '../../core/constants/common.enum';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Constants } from '../../core/constants/constant';
import { Router} from '@angular/router';
import { CreateGuestRes, CreateReq } from '../../core/constants/call.modal';
import { VideoCallService } from '../../core/services/video-call/video-call.service';
import { AgentResponseData, AgentStatus } from '../../core/constants/agent.modal';
import { UtilsService } from '../../core/services/utils/utils.service';

@Component({
  selector: 'app-message-us',
  templateUrl: './message-us.component.html',
  styleUrls: ['./message-us.component.scss']
})
export class MessageUsComponent implements OnInit, OnDestroy {
  agentDetails: AgentResponseData;
  clientUniqueID: string | any;
  emailValidator = [Validators.pattern(Constants.emailRegex), Validators.required];
  ip: string;
  messageOptions = MessageOptions;
  messageSend = false;
  messageUsForm: UntypedFormGroup;
  messageType = this.messageOptions[0];
  phoneValidator = [Validators.pattern(Constants.phoneNumRegex), Validators.required];

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private utils: UtilsService,
              private snowplowService: SnowplowService,
              private videoCallService: VideoCallService) { }

  ngOnInit(): void {
    this.getIP();
    this.messageUsForm = this.fb.group({
      name: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', this.emailValidator),
      phone: new UntypedFormControl('', this.phoneValidator),
      options: new UntypedFormControl(this.messageOptions[0]),
      message: new UntypedFormControl('')
    });
    this.messageUsForm.get('phone')?.clearValidators();
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.clientUniqueID = this.snowplowService.getSnowplowUserId();
    this.messageUsForm.get('options')?.valueChanges.pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
    ).subscribe((val: string) => {
       if (val === this.messageOptions[0]) {
         this.messageUsForm.get('email')?.setValidators(this.emailValidator);
         this.messageUsForm.get('phone')?.clearValidators();
         this.messageType = this.messageOptions[0];
       } else {
         this.messageUsForm.get('phone')?.setValidators(this.phoneValidator);
         this.messageUsForm.get('email')?.clearValidators();
         this.messageType = this.messageOptions[1];
       }
      this.messageUsForm.get('email')?.updateValueAndValidity();
      this.messageUsForm.get('phone')?.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  getIP() {
    this.utils.getIPAddress().subscribe((data: string) => {
      this.ip = data ? data : Constants.defaultIp;
    });
  }

  close(): void {
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }]);
  }

  createGuest(): void {
    const name = this.messageUsForm?.controls['name']?.value;
    const email = this.messageUsForm?.controls['email']?.value;
    const phone =  this.messageUsForm?.controls['phone']?.value;
    const req: CreateReq = {
      user_agent: navigator.userAgent,
      client_user_id: this.clientUniqueID,
      full_name : name ? name : null,
      tenantid: this.agentDetails.tenant_id,
      ip: this.ip
    }
    if(this.messageType === 'email') {
      req.email = email
    } else {
      req.phone = `+1${phone}`
    }
    this.videoCallService.createGuest(req).pipe(takeUntil(this.destroy$)).subscribe((res: CreateGuestRes) => {
      this.sendMessage(res.guesttoken);
    })
  }

  sendMessage(guestToken: string): void {
    const content = { body : this.messageUsForm?.controls['message']?.value}
    const req: OminiReq = {
      channel: this.messageType,
      content: JSON.stringify(content)
    }
    this.videoCallService.ominiChannelSend(req, guestToken).subscribe(()=>{
      this.messageSend = true;
    })
  }
}
