import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AgentStatus } from '../../core/constants/agent.modal';
import {
  InviteData,
  PluginStates,
  RoomData,
  RoomInfoStates,
  RoutesUrls
} from '../../core/constants/common.enum';
import { Constants } from '../../core/constants/constant';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import { SocketService } from '../../core/services/socket/socket.service';
import { UtilsService } from '../../core/services/utils/utils.service';
import { VideoCallService } from '../../core/services/video-call/video-call.service';
import { CallStatus } from '../../core/constants/call.modal';
import { GtmService } from '../../core/services/gtm/gtm.service';
import { GtmTrackers } from '../../core/constants/trackerLabels';

@Component({
  selector: 'app-call-in-future',
  templateUrl: './call-in-future.component.html',
  styleUrls: ['./call-in-future.component.scss']
})
export class CallInFutureComponent implements OnInit, OnDestroy {
  scheduledDate: string;
  scheduledTime: string;
  showBooking: boolean;
  bookingStatus: string;
  spTrackerLabels = Constants.spTrackerLabels;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private utils: UtilsService,
    private translate: TranslateService,
    private router: Router,
    private socketService: SocketService,
    private snowplowService: SnowplowService,
    private videoCallService: VideoCallService,
    private gtmService: GtmService) {
    this.socketService.roomData
      .pipe(takeUntil((this.destroy$)))
      .subscribe({
        next: (data: RoomData) => {
          const currentRoomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
          if (+data.queue_position_id === currentRoomInfo?.queuePositionId) {
            this.gtmService.sendGtm(GtmTrackers.optimyCallStarted);
            this.getRoomInfoAndNavigate(data);
          }
        }
      });
  }

  ngOnInit(): void {
    const currentLocale = this.utils.checkAndGetCurrentLang();
    moment.locale(currentLocale);
    this.setDateAndTime();
    this.utils.setLocalVal(null, [PluginStates.isBookingConfirmed], [false]);
    this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.screenShown,
      this.spTrackerLabels.futureCall, this.spTrackerLabels.version, 1);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  getRoomInfoAndNavigate(data: RoomData) {

    this.videoCallService.getRoomStatus(data?.room_name)
      .pipe(catchError(() => {
        return of(null);
      }), takeUntil(this.destroy$),
      ).subscribe((roomInfo: any) => {
        if (roomInfo) {
          const inviteLink = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.inviteLink);
          const inviteCode = this.utils.getCodeFromInviteLink(inviteLink);
          this.videoCallService.getRoomFromInvite(inviteCode).subscribe((res: InviteData) => {
            if (res.status === CallStatus.in_progress) {
              this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.roomStatus], [res.status]);
              const agentInfo = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
              this.utils.storeValueForUserInfoAndDevice(agentInfo?.default_queue_id, agentInfo?.tenant_id);
              this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }], {});
            }
          });
        }
      });
  }

  setDateAndTime() {
    const startDate = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.startDt);
    const dateFormat = 'ddd, MMM DD YYYY';
    const todayDate = moment().format(dateFormat);
    const isTomorrow = moment(todayDate).clone().add(1, 'days').format(dateFormat);
    const scheduledDate = moment(startDate).format(dateFormat);
    this.scheduledTime = moment(startDate).format('LT');
    if (scheduledDate === isTomorrow) {
      this.scheduledDate = this.translate.instant('common.tomorrow');
      return;
    } else if (scheduledDate === todayDate) {
      this.scheduledDate = this.translate.instant('common.today');
      return;
    }
    this.scheduledDate = moment(startDate).format('ddd, MMM DD');
  }

  close() {
    this.utils.removeLocalStoreVal(
      Constants.optimyLocalStore,
      [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo])
    this.utils.removeSessionStorage();
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }]);
  }
}
