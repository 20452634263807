<div class="video-container">
    <div class="video-container__call">
        <app-in-call [roomName]="roomName" [guestToken]="guestToken" [isOutboundCall]="isOutboundCall"
            (outboundCallAnswer)="outboundCallAnswer($event)"></app-in-call>
    </div>
    <div class="video-container__status" *ngIf="isUserInStatus && !isUserInQueue && !isUserInCall">
        <app-call-status-screen *ngIf="callStatusInfo" [iconName]="callStatusInfo.icon"
            [callStatus]="callStatusInfo.callStatus" [message]="callStatusInfo.msg"></app-call-status-screen>
    </div>
    <app-call-status-screen *ngIf="isLinkExpired" [iconName]="'session-link-expired'"
        [callStatus]="'inCall.callSessionLinkExpired'" [message]="'inCall.linkExpiredMsg'"></app-call-status-screen>
</div>
