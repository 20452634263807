<div class="schedule-appointment" *ngIf="bookingConfig">
  <div class="schedule-appointment__header op-d-flex op-align-items-center op-mb-3 op-mt-3">
    <svg-icon name="schedule-live-session-calendar" class="calendar-icon op-mr-3"></svg-icon>
    <span [innerHTML]="'bookingAppointment.scheduleLiveSession' | translate"></span>
  </div>
  <div class="schedule-appointment__no-appointment" *ngIf="!hasAvailabilityForCalls">
    <div class="op-mb-4 op-mt-5">
      <span>{{ 'bookingAppointment.noSlotsAvailable' | translate}} </span>
    </div>
    <button class="op-btn op-btn-danger op-btn-stroked op-mr-2" [innerHTML]="'common.cancel' | translate"
      (click)="onCancelClick()"></button>
  </div>
  <ng-container *ngIf="hasAvailabilityForCalls">
    <div *ngIf="modalToShow === 1">
      <div class="schedule-appointment__text op-mb-4 op-mt-5" *ngIf="!showConflictErrorMsg">
        {{ editForm ? ('bookingAppointment.rescheduleMessage' | translate):
        ('bookingAppointment.dateSelectionText' | translate : {duration: duration})}}
      </div>
      <div *ngIf="showConflictErrorMsg" class="schedule-appointment__conflict-error"
        [innerHTML]="'bookingAppointment.conflictErrorMsg'| translate">
      </div>
      <div *ngIf="modalToShow === 1 && !openDatePicker">
        <div
          class="op-d-flex op-flex-row schedule-appointment__month-info op-mb-3 op-align-items-center op-justify-content-center">
          <span>{{currentMonth}}</span>
          <i class="op-ml-2 pointer" (click)="openFullDatePicker()"></i>
        </div>
        <div class="schedule-appointment__week-info op-d-flex op-align-items-center op-mb-3">
          <i class="l-arrow op-mr-4" (click)="onClickOfNextOrPrevious(false)"
            [ngClass]="{'arrow-disabled' :checkArrowDisabled()}"></i>
          <div class="date-container op-d-flex op-justify-content-between">
            <div *ngFor="let day of currentDaysInfo; let i = index;"
              class="op-d-flex op-flex-column op-align-items-center op-justify-content-center day-info pointer"
              (click)="setDate(day)" appAddSnowplowEvent [category]="spTrackerLabels.bookingDateSelection"
              [label]="editForm ? spTrackerLabels.miniDateReschedule : spTrackerLabels.miniDate"
              [action]="spTrackerLabels.selectDate"
              [ngClass]="{'active' : selectedEvent === getSelectedDateFormat(day?.dateStruct)}"
              [attr.disabled]="checkDisabledDate(day)">
              <span class="day">{{getDayFormat(day, true)}}</span>
              <span class="date">{{getDayFormat(day, false)}}</span>
            </div>
          </div>
          <i class="r-arrow op-ml-4 pointer" (click)="onClickOfNextOrPrevious(true)"></i>
        </div>
        <div #timeElement class="schedule-appointment__time-info op-mb-4 op-mt-3" id="time-window">
          <div *ngFor="let time of availableTimeSlots"
            class="time op-d-flex op-justify-content-center op-align-items-center pointer op-mb-2" id="{{time}}"
            (click)="setTime(time)" [attr.disabled]="!selectedDate" appAddSnowplowEvent
            [category]="spTrackerLabels.bookingDateSelection"
            [label]="editForm ? spTrackerLabels.miniDateReschedule : spTrackerLabels.miniDate"
            [action]="spTrackerLabels.selectTime" [ngClass]="{'active' : selectedTime === time}">
            {{time}}
          </div>
        </div>
      </div>
      <div *ngIf="openDatePicker && modalToShow === 1" class="op-mb-3 op-mt-5 op-d-flex op-flex-column">
        <ngb-datepicker #dp [navigation]="navigation" [outsideDays]="'collapsed'" [(ngModel)]="DatePickerSelectedDate"
          [markDisabled]="getStartDate()" (navigate)="navigateFromDatePicker($event, true)"></ngb-datepicker>
        <button class="op-btn op-btn-flat op-btn-primary op-flex-1"
          [innerHTML]="'bookingAppointment.selectDate' | translate" appAddSnowplowEvent
          [category]="spTrackerLabels.bookingDateSelection" [action]="spTrackerLabels.selectDate"
          [label]="editForm ? spTrackerLabels.fullMonthReschedule : spTrackerLabels.fullMonth"
          (click)="closeDatePicker()" [disabled]="!getNgbDatePickerSelectedValue()"></button>
      </div>
      <div *ngIf="modalToShow === 1" class="button-container op-mb-3 op-d-flex op-justify-content-between">
        <button class="op-btn op-btn-danger op-btn-stroked op-mr-2" appAddSnowplowEvent
          [category]="spTrackerLabels.bookingDateSelection" [label]="openDatePicker ? (editForm ?
              spTrackerLabels.fullMonthReschedule :spTrackerLabels.fullMonth) :
              (editForm ? spTrackerLabels.miniDateReschedule : spTrackerLabels.miniDate)"
          [action]="openDatePicker ? spTrackerLabels.back : spTrackerLabels.cancel"
          [innerHTML]="'common.cancel' | translate" (click)="onCancelClick()"></button>
        <button class="op-btn op-btn-flat op-btn-primary op-flex-1" [innerHTML]="'common.continue' | translate"
          [disabled]="getDisabledValue()" (click)="onSubmitClick()" appAddSnowplowEvent
          [category]="spTrackerLabels.bookingDateSelection" [label]="openDatePicker ? (editForm ?
              spTrackerLabels.fullMonthReschedule :spTrackerLabels.fullMonth) :
              (editForm ? spTrackerLabels.miniDateReschedule : spTrackerLabels.miniDate)"
          [action]="spTrackerLabels.continue"></button>
      </div>
    </div>
    <div [hidden]="modalToShow !== 2">
      <app-schedule-appointment-form (emitService)="checkEmitService($event)" [selectedTime]="selectedTime"
        [selectedDate]="selectedDate" (onsubmitClick)="onNextClick($event)" [duration]="duration"
        [agentDetails]="agentDetails" [editForm]="editForm" [bookingConfig]="bookingConfig" [modalToShow]="modalToShow">
      </app-schedule-appointment-form>
    </div>
    <div *ngIf="modalToShow === 3">
      <app-schedule-appointment-confirmation [form]="bookingForm" [selectedDate]="selectedDate"
        [selectedTime]="selectedTime" (confirmBookingEvent)="onNextClick()" (backEvent)="checkEmitService($event)"
        [duration]="duration" [editForm]="editForm" [timeZoneInfo]="getTimeZoneInfo()" [formFieldsInfo]="formFieldsInfo"
        (scheduleConflictEvent)="onScheduleConflictClick()"></app-schedule-appointment-confirmation>
    </div>
    <div *ngIf="modalToShow === 4">
      <app-schedule-appointment-success (rescheduleOrCancelEvent)="onRescheduleCancelClick($event)"
        (editEvent)="onEditClick()" [editForm]="editForm" [zone]="zone"></app-schedule-appointment-success>
    </div>
  </ng-container>
</div>
<optimy-modal [openModal]="openBookingModal.asObservable()" [closeOnOutsideClick]="false"
  [closeModal]="closeBookingModal.asObservable()">
  <app-schedule-appointment-edit-cancel *ngIf="showCancelModal" [bookingStatusParams]="bookingStatusParams"
    [zone]="zone" [bookingEditCancel]="bookingEditCancel" (backClick)="onBackClick($event)"
    [isCallFuture]="isCallFuture" (closeModal)="onContinueClose()"></app-schedule-appointment-edit-cancel>
</optimy-modal>