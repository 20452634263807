/* eslint-disable max-len */
export const French = {
  agentNotAvailable: {
    dontWait: 'Vous ne souhaitez pas patienter ?',
    notAvailable: 'Désolé ! Il n\'y a pas d\'agent en direct pour le moment'
  },
  bookingAppointment: {
    availableTime: 'Disponible uniquement au moment de la session',
    cancelled: 'Annulé',
    cancelSessionText: 'Votre session vidéo en direct a bien été annulée',
    cellPhone: 'Entrée invalide. Veuillez saisir les 10 chiffres numériques.',
    charactersLimit: 'Limite de caractères {{characterCount}} / {{maxCharactersLimit}}',
    confirmationText: 'Votre session vidéo en direct est prévue pour',
    confirmationText1: 'Nous avons envoyé une lettre de confirmation à votre adresse e-mail avec le lien de la session. Veuillez vérifier votre courrier indésirable si vous ne le voyez pas. Vous pouvez également ajouter à votre propre calendrier de la manière indiquée ci-dessous.',
    confirmationUpdatedText: 'Votre session vidéo en direct mise à jour est prévue pour',
    conflictErrorMsg: 'La date et l\'heure que vous avez sélectionnées ne sont plus disponibles. Veuillez choisir une nouvelle date et/ou heure.',
    contactInfo: 'Veuillez fournir vos coordonnées',
    contactInformation: 'Informations de contact',
    continueEditSession: 'Continuer à replanifier la session',
    continueToCancel: 'Voulez-vous vraiment annuler cette session programmée?',
    dateSelectionText: 'Veuillez sélectionner une date et un créneau horaire disponible pour votre session en direct de {{duration}} minutes',
    dateTime: 'Date et heure',
    editOrConfirmInformation: 'Veuillez modifier ou confirmer vos coordonnées',
    editSessionText: 'Vous pouvez aussi',
    editSessionText1: 'cette session',
    email: 'Entrée invalide. Veuillez saisir un e-mail valide xxxxx@xxxx.xx.',
    finalConfirmation: 'Vous êtes sur le point de planifier une session en direct. Veuillez consulter les détails ci-dessous et confirmer votre demande:',
    firstName: 'Entrée invalide. Veuillez saisir uniquement des caractères alphabétiques.',
    googleCalendar: 'Ajouter à Google Agenda',
    icsDescription1: 'Nous sommes ravis de vous rencontrer lors de votre session vidéo en direct. Vous pouvez rejoindre la réunion en utilisant ce lien',
    icsDescription2: 'Si vous devez apporter une modification à votre session, vous pouvez modifier la session en utilisant ce lien',
    icsDescription3: 'ou annuler la session en utilisant ce lien',
    icsFile: 'Télécharger le fichier de calendrier (.ics)',
    icsTitle: 'Votre réservation avec',
    isConfirmed: 'est confirmé',
    keepSession: 'Garder la session',
    lastName: 'Entrée invalide. Veuillez saisir uniquement des caractères alphabétiques.',
    message: 'Un message',
    noSlotsAvailable: 'Désolé. il n\'y a présentement aucune place disponible ',
    postalCode: 'Code postal (obligatoire)',
    postalCodeLabel: 'Code postal',
    previousSessionCancelled: 'Votre session précédente a été annulée.',
    rescheduleMessage: 'Vous pouvez commencer à reprogrammer en sélectionnant une autre date et/ou créneau horaire disponible. Votre session programmée existante ne changera pas tant que vous n\'aurez pas soumis le nouveau programme',
    rescheduleSession: 'Replanifier la session',
    responseRequired: 'Réponse requise',
    salesAgencyEmailError: 'Veuillez entrer une adresse courriel de companie valide',
    scheduleDate: 'La session est actuellement programmée le <strong>{{cancelDate}}</strong>.',
    scheduledBy: 'Programmé par',
    scheduledCallText: 'Votre session vidéo en direct est actuellement prévue pour',
    scheduledLiveSessionInfo: 'La session programmée est activée',
    scheduledLiveSessionSuccessText1: 'Un e-mail de confirmation a été envoyé dans votre boîte aux lettres. Veuillez vérifier votre dossier spam ou corbeille si vous ne le recevez pas.',
    scheduleLiveSession: 'Planifier une session en direct',
    scheduleLiveSessionConfirmText: 'Veuillez vérifier les détails de l\'horaire et soumettre lorsque vous êtes prêt à planifier',
    scheduleLiveSessionFormText: 'Dites-nous qui vous êtes et comment nous pouvons vous aider',
    segmentName: 'Organisation',
    selectDate: 'Sélectionner une date',
    selectedInfo: 'Votre session de {{duration}} minutes sera programmée pour',
    sessionInfo: 'Ce lien de session sera ouvert pour que vous puissiez vous joindre au moment de la session.',
    sessionLink: 'Votre lien de session deviendra actif à ce moment et est disponible ci-dessous.',
    sessionSchedule: 'Calendrier des sessions',
    successText: 'Votre session est planifiée',
    successText1: 'Un e-mail de confirmation a été envoyé avec le lien de la session. Nous vous encourageons à vérifier votre courrier indésirable si vous ne le voyez pas dans votre boîte de réception.',
    successText2: 'Nous vous recommandons également d\'ajouter la session à votre calendrier de choix en utilisant les liens ci-dessous.',
    thanksRescheduleSession: 'Merci! Votre session a été reprogrammée',
    thanksScheduleSession: 'Merci! Votre session en direct est programmée!',
    userInfoRescheduleText: 'Vous reprogrammez votre session',
    yesCancelSession: 'Oui, annuler la session'
  },
  callInFuture: {
    callScheduled: 'Bonjour ! Cet appel est prévu pour',
    callStartShortly: 'Bonjour ! Cet appel en direct est en cours',
    excitedToSpeak: 'Nous sommes ravis de vous parler. Votre representant doit vous rencontrer à:',
    pleaseCheckTime: 'Veuillez vous enregistrer à votre session à l`heure prévue et nous serons prêts pour vous.',
    talkToYouSoon: 'À bientôt !',
    yourVideoIsComing: 'Votre session arrive bientôt!'
  },
  common: {
    and: 'et',
    andOther: 'et {{count}} d\'autres sont',
    andOthers: 'et {{count}} d\'autres sont',
    at: 'à',
    back: 'Retour',
    cancel: 'Annuler',
    cancelled: 'Annulé',
    close: 'Fermer',
    cobrowseOn: 'Cobrowse est activé.',
    confirm: 'Confirmer',
    connecting: 'Connexion...',
    continue: 'Continuer',
    edit: 'Éditer',
    employeeAssistance: 'Souhaitez-vous l`aide d`un de nos employés vidéo en direct?',
    from: 'De',
    guest: 'Invité',
    internationalPhoneNumberError: 'Numero de telephone invalide',
    is: 'est',
    leave: 'Partir',
    live: 'En direct',
    liveVideoSupport: 'Assistance vidéo en direct',
    newMessage: 'nouveau message !',
    newMessages: 'nouveau message !',
    no: 'Non',
    noThanks: 'Non, merci',
    okay: 'D\'accord',
    open: 'Ouvert',
    optional: 'Optionnel',
    or: 'ou',
    poweredBy: 'Alimenté par Optimy',
    reply: 'Réponse',
    required: 'Obligatoire',
    reschedule: 'Replanifier',
    save: 'Sauvegarder',
    scheduleSession: 'Planifier session en ligne',
    sendMsg: 'Envoyer un message',
    startLiveVideo: 'Lancer la vidéo en direct',
    stop: 'Arrêter',
    stopCobrowse: 'Arrêter Cobrowse',
    submit: 'Soumettre',
    time: 'Heure',
    to: 'À',
    unavailable: 'Indisponible',
    version: 'Version {{pluginVersion}}',
    videoChat: 'Chat vidéo',
    specialistLive: 'Rencontrez un spécialiste en direct !',
    fabIconMessage: 'Ne vous inquiétez pas, votre audio et vidéo est désactivé.',
    notNow: 'Pas maintenant.',
    tomorrow: 'Demain',
    today: 'Aujourd\'hui',
    typing: 'en train de taper',
    yes: 'Oui',
    you: 'Toi'
  },
  inCall: {
    addAnotherParticipant: 'Ajouter un participant',
    areYouSureAboutLeave: 'Êtes-vous sûr de vouloir quitter l\'appel en direct ?',
    atConvenience: 'à votre convenance.',
    atFutureTime: 'à un meilleur moment.',
    audioDisruption: 'Vous rencontrez peut-être des perturbations vidéo ou audio.',
    audioOption: 'Option Audio',
    callCanceled: 'Cet appel a été annulé',
    callCancelledOrEndedMsg: 'Pour recevoir une assistance supplémentaire, veuillez fermer ce message et cliquer sur l\'icône d\'appel en direct.',
    callEnded: 'Désolé ! Cet appel en direct est terminé',
    callNotStartedMsg: 'Notre agent sera à vos côtés',
    callSessionLinkExpired: 'Oups ! Ce lien d\'appel en direct a expiré',
    camera: 'Caméra',
    cameraPermissionLabel: 'Veuillez accorder les autorisations pour accéder à votre caméra',
    cameraPermissionText: 'Cet outil d\'assistance en direct a besoin de votre caméra pour que les autres participants puissent vous voir. Il vous demandera de confirmer cette décision sur chaque navigateur et ordinateur que vous utilisez. Pour aider à protéger votre vie privée, la vidéo sera désactivée par défaut.',
    camError: 'L`accès à la caméra est refusé. Veuillez accorder l`accès dans les paramètres de votre navigateur.',
    continueBrowseMsg: 'Pendant que vous attendez dans la file d`attente, vous pouvez continuer à naviguer.',
    chat: {
      retryMessage: 'Le message n\'a pas pu être envoyé. Tapez pour réenvoyer. ',
      joinMessage: '{{userName}} a rejoint la conversation',
      leftMessage: '{{userName}} a quitté la conversation',
      privacyMsg: 'En continuant à utiliser ce produit, vous acceptez notre',
      privacyPolicy: 'politique de confidentialité.'
    },
    continuInvite: 'Continuer à inviter',
    copyInvite: 'Copier le lien d\'invitation',
    copyLink: 'Copier le lien',
    displayPreview: 'Cet affichage n\'est qu\'un aperçu .Appuyez sur <strong>Sauvegarder les paramètres</strong> pour les appliquer.',
    emailPhoneError: 'Entrée invalide. Veuillez réessayer avec un format d\'e-mail valide ou 10 chiffres seulement.',
    enterName: 'Entrez le nom (facultatif)',
    ifYouPrefer: 'Si vous préférez, vous pouvez également',
    inputOutput: 'Entrée / sortie',
    inTimeLeft: 'dans {{timeLeft}} minutes',
    inviteDirectly: 'ou invitez-les directement par e-mail ou par téléphone',
    inviteError: 'Un ou plusieurs email et/ou numéro de téléphone ne sont pas valides',
    inviteInfo: 'Séparez chaque contact d\'invitation à l\'aide d\'une virgule (,) ou d\'une entrée ou d\'un espace',
    inviteInfoShort: 'Courriel ou numéro de téléphone',
    inviteOption1: 'Copiez ou partagez ce lien d\'appel avec les personnes que vous souhaitez voir participer à l\'appel.',
    inviteOption2: 'Vous pouvez également inviter des invités par e-mail ou par téléphone.',
    invitePeople: 'Inviter',
    invitesSent: 'Invitations envoyées',
    leave: 'Partir',
    leaveThisCall: 'Quittez cet appel',
    linkCopied: 'Lien d\'invitation copié',
    linkExpiredMsg: 'Si vous souhaitez participer à un appel en direct avec un expert, fermez ce message et cliquez sur l\'icône d\'appel en direct.',
    liveExpert: 'Expert en direct',
    menu: {
      audioVideo: 'Audio et vidéo',
      corner: 'Coin (par défaut)',
      fullScreen: 'Plein écran',
      invite: 'Invitez',
      minimized: 'Minimisé',
      sessionSettings: 'Paramètres de la session',
      settings: 'Paramètres',
      windowSize: 'Taille de la fenêtre'
    },
    micError: 'L`accès au microphone est refusé. Veuillez accorder l`accès dans les paramètres de votre navigateur.',
    micPermissionLabel: 'Veuillez accorder des autorisations pour accéder à votre microphone',
    micPermissionText: 'Cet outil d\'assistance en direct a besoin de votre microphone pour que les autres participants puissent vous entendre. Il vous demandera de confirmer cette décision sur chaque navigateur et ordinateur que vous utilisez.',
    microphone: 'Micro',
    minuteLeft: '<b>{{timeLeft}} minutes</b> restantes',
    minimize: 'Minimisé',
    noAgentAtMoment: 'Il n`y a pas d`agent disponible pour le moment',
    notifyMsg: 'et nous vous informerons lorsqu`un agent est disponible, ou',
    ok: 'D`accord',
    oneOfTheAgent: 'ou l\'un de nos autres agents',
    outboundPrivacy: 'Votre appareil photo et votre microphone sont éteints.',
    outboundPrivacyTooltip: 'Afin de protéger votre confidentialité et votre sécurité, votre caméra et votre microphone sont désactivés par défaut.',
    permissionError: 'votre caméra et votre microphone resteront désactivés.',
    pleaseSelect: 'Veuillez sélectionner',
    positionOnQueue: {
      youAreCurrently: 'Vous êtes actuellement',
      inLine: '{{position}} en ligne'
    },
    reconnect: 'Se reconnecter',
    settings: 'Paramètres',
    scheduleAnAppointment: 'Planifier un rendez-vous',
    scheduleAppointment: 'Prendre un rendez-vous',
    sendInvitation: 'Envoyer une invitation',
    sendInvite: 'Envoyer une invitation',
    sendInviteGuest: 'Invitations envoyées avec succès à l\'invité',
    sendInviteGuests: 'Invitations envoyées avec succès à {{totalGuests}} invités',
    sendInviteQuestion: 'Envoyer une invitation aux invités suivants?',
    shareInvite: 'Partager l\'invitation',
    sorryNoAgent: 'Nous sommes désolés! Il n`y a pas d`agent disponible pour le moment.',
    speaker: 'Haut-parleur',
    stayInQueue: 'Restez en file d`attente',
    stayOnTheCall: 'Restez sur l\'appel',
    textBack: 'Renvoyer un message',
    timeLeft: '{{timeLeft}} minutes restantes',
    typeMessage: 'Tapez votre message ici',
    untilNextHelp: 'Jusqu`à la prochaine aide disponible',
    videoSettings: 'Paramètres vidéo',
    welcomeMessage: 'Bienvenue! Un agent sera avec vous dans environ',
    willBeWithYouShortly: 'sera avec vous sous peu'
  },
  messageUs: {
    enterInfo: 'Veuillez saisir vos informations, votre méthode de contact préférée et la manière dont nous pouvons vous aider.',
    email: 'E-mail (obligatoire)',
    howCanIHelp: 'Comment pouvons-nous vous aider ? (facultatif)',
    name: 'Nom (obligatoire)',
    phone: 'téléphone (obligatoire)',
    replyMethod: 'Comment voulez-vous que nous vous répondions ?',
    send: 'Envoyer',
    sendAMessage: 'Envoyer un message',
    thanksMessage: 'Merci ! Votre message a été reçu !',
    contactMessage: 'L\'un de nos spécialistes vous répondra dans les plus brefs délais.',
    twoBusiness: '2 jours ouvrables.'
  },
  noAgentModal: {
    noAgent: 'Il n\'y a aucun agent en direct présentement ',
    scheduleCall: 'Prévoir un appel',
    leaveMessage: 'Laisser un message'
  },
  placeholders: {
    cellPhone: 'Numéro de téléphone *',
    companyName: 'Nom de la companie',
    defaultField: 'Nom (obligatoire)',
    emailId: 'E-mail *',
    firstName: 'Prénom *',
    lastName: 'Nom de famille *',
    fullName: 'Nom complet *',
    postalCode: 'Code Postal (obligatoire)',
    roleYouAreHiringFor: 'Le role pour lequel vous embauché',
    specifyRequest: 'Aidez-nous à préparer la session en partageant des détails sur des questions spécifiques ou des produits d\'intérêt.'
  },
  timeKillers: {
    email: 'Adresse Courriel',
    emailError: 'Veuillez réessayer avec un format d\'adresse courriel valide ',
    emailPhoneError: 'Veuillez entrez votre addresse courriel ou numéro de téléphone',
    leaveFormTitle: 'Remplissez vos informations et notre agent vous contactera dans les plus brefs délais.',
    leaveQueue: 'Quitter la file d\'attente ?',
    leaveQueueMessage: 'Vous perdrez votre place pour parler à un agent.',
    message: 'Message (facultatif)',
    messageDelivered: 'Message délivré',
    messageMe: 'Rappelez-moi',
    name: 'Nom',
    nameError: 'Le nom est requis',
    phone: 'Téléphone',
    phoneError: 'Le téléphone est requis',
  },
  userFeedback: {
    improveQuestion: 'Comment pouvons nous nous améliorer? (facultatif)',
    liveSessionEndedText: 'La session en direct avec <strong>{{agentName}}</strong> est terminée',
    liveSessionEndedTextNoAgentInfo: 'La session en direct est terminée',
    rateText: 'Veuillez évaluer votre expérience globale',
    sendFeedback: 'Envoyer des commentaires',
    skitAndExit: 'Passer et quitter',
    thanksForFeedback: 'Merci d\'avoir fourni vos commentaires!'
  },
  userForm: {
    agreementEnd: 'Votre vidéo est automatiquement désactivée au début de l`appel.',
    agreementStart: 'En cliquant sur Rejoindre, vous acceptez le, ',
    categoryError: 'Entrée invalide. Veuillez sélectionner une catégorie.',
    categoryRequired: 'Catégorie (obligatoire)',
    connectWithAgent: 'Connectez-vous avec un agent',
    email: 'Courriel',
    joinCall: 'Rejoindre l`appel (vidéo désactivée)',
    joinOngoingCall: 'Rejoignez un appel en cours',
    name: 'Nom',
    optional: '(Optionnel)',
    phone: 'Téléphone',
    policy: 'Politique de confidentialité.',
    required: '(Requis)'
  },
  userFormErrors: {
    email: 'Entrée invalide. Veuillez réessayer avec un format d\'e-mail valide',
    name: 'Entrée invalide. Veuillez saisir uniquement des caractères alphabétiques.',
    phone: 'Entrée invalide. Veuillez réessayer avec seulement 10 chiffres.',
    postalCode: 'Entrée invalide. Veuillez réessayer avec un format de code postal canadien valide.'
  }
}
