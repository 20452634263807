<div class="form-container op-mt-5" *ngIf="!showConfirmation">
  <div class="form-container__text op-mb-4" [innerHTML]="'bookingAppointment.scheduleLiveSessionFormText' | translate"></div>
  <form [formGroup]="bookingForm" class="op-mb-3">
    <app-sales-talent-booking-form *ngIf="openSalesTalentForm" [bookingForm]="bookingForm"></app-sales-talent-booking-form>
    <div *ngIf="!showIframe && !openSalesTalentForm">
    <div class="name-container op-mb-3">
      <input type="text" class="op-mb-2 user-info firstName" [placeholder]="'placeholders.fullName' | translate" [formControlName]="'full_name'" [ngClass]="{ 'is-invalid' : getFormControlInfo('full_name')}">
      <small class="text-danger"
             *ngIf="getFormControlInfo('full_name')" [innerHTML]="'bookingAppointment.firstName' | translate">
      </small>
    </div>
    <div class="op-mb-3 postalcode-container">
      <input aria-label="email" class="op-mb-2 user-info" [placeholder]="'placeholders.emailId' | translate" type="text" [formControlName]="'email'" [ngClass]="{ 'is-invalid' : getFormControlInfo('email')}">
      <small class="text-danger"
             *ngIf="getFormControlInfo('email')" [innerHTML]="'bookingAppointment.email' | translate">
      </small>
    </div>
    <app-intel-tell-input (sendPhoneNumber)="setPhoneNumberValue($event)"></app-intel-tell-input>
    <div *ngIf="checkIsMccarthy()">
      <div class="op-mb-3 name-container account-info op-mt-2">
        <input [formControlName]="'schoolName'" id="typeahead-focus" type="text" class="select-dropdown user-info" [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)"
          #instance="ngbTypeahead" [placeholder]="'mccarthyLiveChat.selectName' | translate" />
        <small class="error op-ml-3 op-mt-2"
               *ngIf="getFormControlInfo('schoolName')" [innerHTML]="'mccarthyError.selectSchoolOrWorkplace' | translate">
        </small>
      </div>
    </div>
    <div class="op-mb-3 op-mt-3 specify-request">
      <textarea aria-label="specifyRequest" class="user-info" [placeholder]="getSpecifyRequestPlaceHolder()" [(ngModel)]="characterCount" maxlength="1500" type="text" [formControlName]="'comment'"
        [ngClass]="{ 'is-invalid' : getFormControlInfo('comment')}" rows="4" cols="50"></textarea>
      <div class="op-d-flex op-justify-content-end remaining-char" [innerHTML]="'bookingAppointment.charactersLimit' | translate : {characterCount: characterCount?.length, maxCharactersLimit: maxCharactersLimit }"></div>
    </div>
    </div>
  </form>
  <div *ngIf="showIframe">
    <app-iframe-config [configData]="bookingConfigData" [allInputFields]="allInputFields"
                       [isBooking]="true"
                       (submitIFrameFormEvent)="onSubmitIFrameForm($event)" [modalToShow]="modalToShow"></app-iframe-config>
  </div>
  <div class="op-mb-3 op-d-flex op-justify-content-between">
    <button class="op-btn op-btn-stroked op-btn-danger op-flex-1 op-mr-1" [ngClass]="{'iframe-active': showIframe}"
            appAddSnowplowEvent [category]="spTrackerLabels.bookingForm"
            [action]="spTrackerLabels.cancel"
            [label]="showIframe ? spTrackerLabels.customForm : spTrackerLabels.genericForm"
            (click)="close()" [innerHTML]="'common.cancel' | translate"></button>
    <button class="op-btn op-btn-stroked op-btn-primary op-flex-1 op-mr-1" [ngClass]="{'iframe-active': showIframe}"
            appAddSnowplowEvent [category]="spTrackerLabels.bookingForm"
            [action]="spTrackerLabels.back"
            [label]="showIframe ? spTrackerLabels.customForm : spTrackerLabels.genericForm"
            [innerHTML]="'common.back' | translate" (click)="onBackClick()" ></button>
    <button *ngIf="!showIframe" class="op-btn op-btn-flat op-btn-primary" [innerHTML]="'common.continue' | translate"
            appAddSnowplowEvent [category]="spTrackerLabels.bookingForm"
            [action]="spTrackerLabels.continue"
            [label]="spTrackerLabels.genericForm"
            (click)="onSubmitForm()" [disabled]="!bookingForm?.valid"
      [ngClass]="[bookingForm?.valid ? 'submit' : '']"></button>
  </div>
</div>
