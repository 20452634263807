import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AgentResponseData, AgentStatus } from '../../core/constants/agent.modal';
import { UtilsService } from '../../core/services/utils/utils.service';
import { VideoCallService } from '../../core/services/video-call/video-call.service';
import { Constants } from '../../core/constants/constant';
import {
  CallInfoStates,
  PluginStates,
  RoomInfo,
  RoomInfoStates,
  RoutesUrls,
  UserInfo,
  UserInfoRequiredField,
  UserFormFieldValidations, InviteCodeParams
} from '../../core/constants/common.enum';
import { Router } from '@angular/router';
declare var require: any;
const ics = require('ics');
import { TranslateService } from '@ngx-translate/core';
import { AllInputFields } from 'src/app/core/constants/booking-appointment.modal';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import { SharedService } from '../../core/services/shared/shared.service';

@Component({
  selector: 'app-user-info-form',
  templateUrl: './user-info-form.component.html',
  styleUrls: ['./user-info-form.component.scss']
 })
export class UserInfoFormComponent implements OnInit {
  userForm: UntypedFormGroup;
  agentDetails: AgentResponseData;
  constants = Constants;
  isSubmitted = false;
  ip: string;
  roomInfo: RoomInfo;
  allFormControls: any = [];
  showIframe = false;
  customConfigData: any = {};
  btnValue: string;
  allInputFields: AllInputFields[] = [];
  isJoinCallStatus: boolean;
  spTrackerLabels = Constants.spTrackerLabels;
  withInvite = false;
  genericFormVersion: number;

  constructor(private formBuilder: UntypedFormBuilder,
              private utils: UtilsService,
              private router: Router,
              private videoCallService: VideoCallService,
              private snowplowService: SnowplowService,
              private sharedService: SharedService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.isJoinCallStatus = this.utils.getLocalVal(PluginStates.isJoinCall);
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.userForm = this.formBuilder.group({
      queue_id: ['', Validators.required],
    });
    this.getAgentInfo();
    this.getIP();
  }

  onSubmitIFrameForm(formValues: any){
    this.snowplowService.trackStructEvent(this.withInvite ? this.spTrackerLabels.inviteLink : this.spTrackerLabels.inboundForm,
        this.withInvite ?  this.spTrackerLabels.joinCall : this.spTrackerLabels.joinQueue, this.spTrackerLabels.customForm);
    this.userForm = new UntypedFormGroup({});
    this.userForm = this.utils.setFormControlsForIframe(this.userForm, this.allInputFields, formValues, false);
    let user: UserInfo = this.getUserInfo();
    const formValuesWithCustomFields = this.utils.buildCustomFieldsObject(this.userForm?.controls);
    user = this.getFullUserData(user, formValuesWithCustomFields);
    this.setLocalValAndRedirect(user);
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.userForm?.invalid){
      return;
    }
    const stateValue = this.utils.checkAndRedirectToState();
    if(!stateValue){
      let user: UserInfo = this.getUserInfo();
      const queue_config = this.agentDetails?.join_queue_config;
      if(queue_config){
        const formValuesWithCustomFields = this.utils.buildCustomFieldsObject(this.userForm?.controls);
        user = this.getFullUserData(user, formValuesWithCustomFields);
      }
      this.setLocalValAndRedirect(user);
    }
  }

  setLocalValAndRedirect(user: any){
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.userInfo], [user]);
    this.utils.setLocalVal(PluginStates.callInfo, [CallInfoStates.audio, CallInfoStates.video], [false, false]);
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.video_call] } }], {});
  }

  getUserInfo(){
    const name = this.userForm?.controls['name']?.value;
    const queueId = this.userForm?.controls['queue_id']?.value;
    return {
      full_name : name,
      queue_id: queueId ? queueId : this.agentDetails?.default_queue_id,
      ip: this.ip ? this.ip : Constants.defaultIp,
      tenantid: this.agentDetails?.tenant_id
    }
  }

  getFullUserData(user: any, config: any){
    user = {...user, ...config};
    return user;
  }

  getAgentInfo() {
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.genericFormVersion = this.agentDetails.join_queue_config?.generic_form_version
    let isCustomConfig;
    if (!this.agentDetails) {
      this.utils.getAgentStatusFormRedisCaChe().subscribe(res => {
        isCustomConfig = this.callAgentStatusApi(res);
      }, error => {
        console.log('agent status error', error);
      });
    } else {
      isCustomConfig = this.getCustomFormInfo();
    }
    if (!this.agentDetails?.queues?.length) {
      this.userForm?.controls['queue_id'].clearValidators();
      this.userForm?.controls['queue_id'].updateValueAndValidity();
    }

    if(!isCustomConfig){
      this.getFormControlsInfo();
    }
  }

  callAgentStatusApi(response: AgentResponseData | any){
    // when we implement state management add this function call to effects and remove from here
    this.utils.setAgentStatusToSessionStorage(response);
    this.agentDetails = response;
    return this.getCustomFormInfo();
  }

  getCustomFormInfo(){
    this.customConfigData = this.agentDetails?.join_queue_config?.custom_form;
    const currentLocation = location.href;
    this.withInvite = currentLocation.includes(InviteCodeParams.code) || currentLocation.includes(InviteCodeParams.optimyCode);
    if(this.customConfigData?.pages){
      this.showIframe = true;
      this.btnValue = this.translate.instant('userForm.joinCall');
      this.customConfigData?.pages[0]?.elements.forEach((element: any) => {
        if(element?.name){
          this.allInputFields.push({name: element.name});
        }
      });
    } else {
      if (this.withInvite) {
        this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.screenShown,
            this.spTrackerLabels.genericForm, this.spTrackerLabels.version, 1);
      } else {
        this.snowplowService.trackStructEvent(this.spTrackerLabels.inboundForm, this.spTrackerLabels.screenShown,
            this.spTrackerLabels.genericForm, this.spTrackerLabels.version, this.genericFormVersion);
      }
    }
    return this.customConfigData?.pages ? true : false;
  }

  getFormControlsInfo(){
    const queue_config = this.agentDetails?.join_queue_config;
    const checkVisible = queue_config ? this.checkAllControlVisibleStatus(queue_config): '';
    if(queue_config && checkVisible){
      Object.keys(queue_config).forEach((element: any) => {
        if(queue_config[element]?.visible){
          const isRequired = queue_config[element]?.required ? true : false;
          this.setFormControls(element, isRequired);
          const setPlaceholder = queue_config[element]?.required ? this.translate.instant('userForm.required') : this.translate.instant('userForm.optional');
          this.allFormControls.push({name: element,
          placeholder: `${this.getPlaceHolder(element)} ${setPlaceholder}`})
        }
      });
    } else {
      this.userForm.addControl(UserInfoRequiredField.name, new UntypedFormControl('', Validators.required));
      this.allFormControls.push({name: UserInfoRequiredField.name, placeholder: this.translate.instant('placeholders.defaultField')});
    }
  }

  getPlaceHolder(element: string) {
    if(element === 'name') {
      return this.translate.instant('userForm.name');
    } else if (element === 'email') {
      return this.translate.instant('userForm.email');
    } else if (element === 'phone') {
      return this.translate.instant('userForm.phone');
    }
  }

  checkAllControlVisibleStatus(queue_config: any){
    return Object.keys(queue_config).find((element: any) => queue_config[element]?.visible === 1);
  }

  setFormControls(element: string, isRequired: any){
    const findRegex = UserFormFieldValidations.find((r: any) => r.name === element);
    this.userForm.addControl(element, new UntypedFormControl('', []));
    if(isRequired){
      this.setFormValidators(findRegex, element);
    } else {
      findRegex ? this.userForm?.controls[element].setValidators([Validators.pattern(findRegex?.regex)]) : '';
    }
    this.userForm?.controls[element]?.updateValueAndValidity();
  }

  setFormValidators(regex: any, element: string){
    const isControl = this.userForm?.controls[element];
    return regex ? this.userForm?.controls[element].setValidators([Validators.required, Validators.pattern(regex.regex)])
                : this.userForm?.controls[element].setValidators([Validators.required]);
  }

  getIP() {
    this.utils.getIPAddress().subscribe((data: string) => {
      this.ip = data ? data : Constants.defaultIp;
    });
  }

  getError(name: string){
    return this.translate.instant(`userFormErrors.${name}`);
  }
  getFormControlInfo(name: string) {
    return (this.userForm?.controls[name]?.touched && this.userForm?.controls[name]?.errors) ||
        (this.userForm?.controls[name]?.errors && this.isSubmitted);
  }

  close() {
    this.sharedService.closeCall();
  }

}
