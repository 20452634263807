import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../core/services/shared/shared.service';
import { Constants } from '../../core/constants/constant';
import { RoomInfoStates } from '../../core/constants/common.enum';
import { UtilsService } from '../../core/services/utils/utils.service';

@Component({
  selector: 'app-close-route',
  templateUrl: './close-route.component.html',
  styleUrls: ['./close-route.component.scss']
})
export class CloseRouteComponent implements OnInit {

  constructor(private sharedService: SharedService,
              private utils: UtilsService) { }

  ngOnInit(): void {
    this.utils.removeSessionStorage();
    this.sharedService.hideHeaderAndFooter(false);
    this.sharedService.showAppLauncher(true);
  }

}
