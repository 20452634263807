import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PluginStates, RoomInfoStates, RoutesUrls } from '../../core/constants/common.enum';
import { Constants } from '../../core/constants/constant';
import { SharedService } from '../../core/services/shared/shared.service';
import { UtilsService } from '../../core/services/utils/utils.service';
import { CallStatus, WaitingCallStatus } from '../../core/constants/call.modal';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';

@Component({
  selector: 'app-call-status-screen',
  templateUrl: './call-status-screen.component.html',
  styleUrls: ['./call-status-screen.component.scss']
})
export class CallStatusScreenComponent implements OnInit {
  spTrackerLabels = Constants.spTrackerLabels;
  status: string;

  @Input() iconName: string = '';
  @Input() callStatus: string = '';
  @Input() message: string = '';
  @Input() hideButton = false;
  @Input() waitingTime = false;

  constructor(private router: Router,
    private utils: UtilsService,
    private snowplowService: SnowplowService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.status = this.callStatus.split('.')[1];
    this.sharedService.hideHeaderAndFooter(true);
    const roomStatus = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.roomStatus);
    if(!WaitingCallStatus.includes(roomStatus)){
      this.clearLocalAndSessionStorage();
    }
    if(this.status === CallStatus.callStartShortly) {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.screenShown,
          this.spTrackerLabels.pendingCall, this.spTrackerLabels.version, 1);
    } else if (this.status === CallStatus.callCanceled) {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.screenShown,
          this.spTrackerLabels.cancelledCall, this.spTrackerLabels.version, 1);
    } else if(this.status === CallStatus.callEnded) {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.screenShown,
          this.spTrackerLabels.endedCall, this.spTrackerLabels.version, 1);
    }
  }

  clearLocalAndSessionStorage(){
    this.utils.removeLocalStoreVal(
        Constants.optimyLocalStore,
        [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo])
    this.utils.removeSessionStorage();
  }

  close() {
    this.clearLocalAndSessionStorage();
    if(this.status === CallStatus.callStartShortly) {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.close,
          this.spTrackerLabels.pendingCall);
    } else if (this.status === CallStatus.callCanceled) {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.close,
          this.spTrackerLabels.cancelledCall);
    } else if(this.status === CallStatus.callEnded) {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.close,
          this.spTrackerLabels.endedCall);
    }
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }]);
  }

}
