import { AgentStatus } from './../../constants/agent.modal';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { SnowplowService } from './../snowplow/snowplow.service';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { HttpWrapperService } from '../http-service/http-wrapper.service';
import { RecordingSessionInfo } from './recording-types';

@Injectable({
  providedIn: 'root'
})
export class RecordingService {

  startRecordInteractionAction$: Subject<any> = new Subject<any>();
  stopRecordInteractionAction$: Subject<any> = new Subject<any>();

  constructor(private httpClientWrapper: HttpWrapperService,
    private snowplowService: SnowplowService,
    private utils: UtilsService) {
    this.startRecordInteractionAction$.subscribe(() => {
      this.startRecordInteraction(this.getSessionInfo()).pipe(take(1)).subscribe(res=>{
        console.log('recording Started');
      });
    });
    this.stopRecordInteractionAction$.subscribe(() => {
      this.stopRecordInteraction(this.getSessionInfo()).pipe(take(1)).subscribe(res=>{
        console.log('recording Stopped');
      });
    });
  }

  private getSessionInfo(): RecordingSessionInfo {
    const domainSessionId = this.utils.getDomainSessionId();
    const domainUserId = this.snowplowService.getSnowplowUserId();
    const agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    const sessionDetected: RecordingSessionInfo = {
      domain_sessionid: domainSessionId,
      domain_userid: domainUserId,
      tenantid: agentDetails.tenant_id,
      guest_hostname: window.location.href
    };
    return sessionDetected;
  }

  private startRecordInteraction(guestData: RecordingSessionInfo): Observable<any> {
    const { domain_userid, domain_sessionid, tenantid, guest_hostname } = guestData
    return this.httpClientWrapper.post('startRecordInteraction', { domain_userid, domain_sessionid, tenantid, guest_hostname })
      .pipe(catchError(e => throwError(e)));
  }

  private stopRecordInteraction(guestData: RecordingSessionInfo): Observable<any> {
    const { domain_userid, domain_sessionid, tenantid, guest_hostname } = guestData
    return this.httpClientWrapper.post('stopRecordInteraction', { domain_userid, domain_sessionid, tenantid, guest_hostname })
      .pipe(catchError(e => throwError(e)));
  }
}
