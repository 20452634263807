export const SnowplowTrackerProperties = {
  callId: 'call_id',
  guestActive: 'guest_active',
  guestInactive: 'guest_inactive',
  numberMsg: 'number_msg',
  unreadMsg: 'unread_msg',
  userId: 'user_id'
}

export const SnowplowTrackerAction = {
  active: 'active',
  cancelButton: 'cancel_button',
  inactive: 'inactive',
  invite: 'invite',
  leaveMessage: 'leavemessage',
  permissionDeny: 'permission_deny',
  permissionDismiss: 'permission_dismiss',
  permissionGrant: 'permission_grant',
  setting: 'settings',
  shown: 'shown',
  soundBlock: 'sound_block',
  soundPlay: 'sound_play',
  titleChangeStart: 'title_change_start',
  titleChangeEnd: 'title_change_end'
};

export const SnowplowTrackerCategories = {
  callFull: 'call_full',
  callFullWaiting: 'call_full_waiting',
  callFullLandscape: 'call_full_landscape',
  callFullWaitingLandscape: 'call_full_waiting_landscape',
  callIntermediate: 'call_intermediate',
  callIntermediateLandscape: 'call_intermediate_landscape',
  callInvite: 'call_invite',
  callInviteLandscape: 'call_invite_landscape',
  callMini: 'call_mini',
  callMiniWaiting: 'call_mini_waiting',
  callMiniLandscape: 'call_mini_landscape',
  callMiniWaitingLandscape: 'call_mini_waiting_landscape',
  callSetting: 'call_setting',
  callSettingLandscape: 'call_setting_landscape',
  callChat: 'call_chat',
  callChatWaiting: 'call_chat_waiting',
  callChatLandscape: 'call_chat_landscape',
  callChatWaitingLandscape: 'call_chat_waiting_landscape',
  callCobrowse: 'call_cobrowse',
  callCobrowseLandscape: 'call_cobrowse_landscape',
  tabActivity: 'tab_activity'
};

export const SnowplowTrackerLabels = {
  addGoogleCalendar: 'add_google_calendar',
  agentsAvailable: 'agents_available',
  audioVideo: 'audio_video',
  autoClose: 'auto_close',
  availableDays: 'available_days',
  availableSlots: 'available_slots',
  avatar: 'avatar',
  back: 'back',
  booking: 'booking',
  bookingCancel: 'booking_cancel',
  bookingConfirmation: 'booking_confirmation',
  bookingDateSelection: 'booking_date_selection',
  bookingExternal: 'booking_external',
  bookingForm: 'booking_form',
  bookingReview: 'booking_review',
  callControlMenu: 'call_control_menu',
  callId: 'call_id',
  callRating: 'call_rating',
  camera: 'camera',
  cancel: 'cancel',
  cancelBooking: 'cancelbooking',
  cancelConfirmation: 'cancel_confirmation',
  cancelledCall: 'cancelled_call',
  cancelSession: 'cancel_session',
  chat: 'chat',
  chatField: 'chat_field',
  click: 'click',
  clickCta: 'click_cta',
  close: 'close',
  cobrowseScreen: 'cobrowse_screen',
  collapse: 'collapse',
  confirmJoinQueue: 'confirmjoinqueue',
  confirmModalWithAgent: 'confirm_modal_with_agent',
  confirmModalNoAgent: 'confirm_modal_no_agent',
  confirm: 'confirm',
  confirmationPage: 'confirmation_page',
  confirmationPageReschedule: 'confirmation_page_reschedule',
  confirmationToast: 'confirmation_toast',
  continue: 'continue',
  controlMenu: 'overflow',
  copy: 'copy',
  cornerView: 'corner_view',
  customFabNoAgent: 'custom_fab_no_agent',
  customFabWithAgent: 'custom_fab_with_agent',
  customForm: 'custom_form',
  default: 'default',
  downloadIcs: 'download_ics',
  durationOnScreen: 'duration_on_screen',
  editBooking: 'editbooking',
  endCall: 'end_call',
  endedCall: 'ended_call',
  exit: 'exit',
  exitQueue: 'exit_queue',
  externalFunctionNoAgent: 'external_function_no_agent',
  externalFunctionWithAgent: 'external_function_with_agent',
  externalLink: 'external_link',
  externalLinkNoAgent: 'external_link_no_agent',
  externalLinkWithAgent: 'external_link_with_agent',
  fabAgentJoin: 'fab_agent_join',
  fabCollapse: 'fab_collapse',
  fabConnecting: 'fab_connecting',
  fabConnectingMenu: 'fab_connecting_menu',
  feedback: 'feedback',
  fullDate: 'full_date',
  fullMonth: 'full_month',
  fullMonthReschedule: 'full_month_reschedule',
  fullView: 'full_view',
  futureCall: 'future_call',
  genericFabNoAgent: 'generic_fab_no_agent',
  genericFabWithAgents: 'generic_fab_with_agent',
  genericForm: 'generic_form',
  genericNudgeNoAgent: 'generic_nudge_no_agent',
  genericNudgeWithAgent: 'generic_nudge_with_agent',
  genericScreen: 'generic_screen',
  greeting: 'greeting',
  invite: 'invite',
  inviteLink: 'invite_link',
  inboundConfirmJoinQueue: 'inbound_confirmjoinqueue',
  inboundExternal: 'inbound_external',
  inboundFab: 'inbound_fab',
  inboundForm: 'inbound_form',
  inboundNudge: 'inbound_nudge',
  inboundQueue: 'inbound_queue',
  joinCall: 'join_call',
  joinQueue: 'joinqueue',
  keepSession: 'keep_session',
  localNotClean: 'local_not_clean',
  leave: 'leave',
  leaveConfirm: 'leave_confirm',
  leaveMsg: 'leave_msg',
  left: 'left',
  mainQueue: 'main_queue',
  manualPing: 'manual_ping',
  maximize: 'maximize',
  maximizeQueue: 'maximize_queue',
  menu: 'menu',
  message: 'message',
  microphone: 'microphone',
  miniDate: 'mini_date',
  miniDateError: 'mini_date_error',
  miniDateReschedule: 'mini_date_reschedule',
  minimize: 'minimize',
  minimizeQueue: 'minimize_queue',
  miniVideoDiv: 'mini_video_div',
  miniQueue: 'mini_queue',
  open: 'open',
  optimy: 'optimy',
  outboundRequest: 'outbound_request',
  overflow: 'overflow',
  panel: 'panel',
  panelTransition: 'panel_transition',
  pendingCall: 'pending_call',
  ping: 'ping',
  privacyPolicy: 'privacy_policy',
  rate: 'rate',
  reconnect: 'reconnect',
  reply: 'reply',
  requestPage: 'request_page',
  requestScreen: 'request_screen',
  reviewPage: 'review_page',
  reviewPageReschedule: 'review_page_reschedule',
  right: 'right',
  save: 'save',
  schedule: 'schedule',
  scheduleCall: 'schedule_call',
  screenShown: 'screen_shown',
  select: 'select',
  selectDate: 'select_date',
  selectTime: 'select_time',
  send: 'send',
  sentToast: 'sent_toast',
  settings: 'settings',
  skip: 'skip',
  sound: 'sound',
  staticNudgeNoAgent: 'static_nudge_no_agent',
  staticNudgeWithAgent: 'static_nudge_with_agent',
  stop: 'stop',
  submit: 'submit',
  submitToast: 'submit_toast',
  switchCamera: 'switch_camera',
  tab: 'tab',
  thank: 'thank',
  title: 'title',
  version: 'version',
  sent: 'sent'
}

export const SnowplowTrackerSchemas = {
  agentSchema: 'iglu:ai.optimy/agent/jsonschema/1-0-0',
  fabSchema: 'iglu:ai.optimy/fab/jsonschema/1-0-0',
  callSchema: 'iglu:ai.optimy/call/jsonschema/1-0-0',
  client_label: 'client_label',
  genericType: 'generic',
  launchSchema: 'iglu:ai.optimy/launch_method/jsonschema/1-0-0',
  nudgeSchema: 'iglu:ai.optimy/nudge/jsonschema/1-0-0',
  joinQueueQuestionSchema: 'iglu:ai.optimy/join_queue_question/jsonschema/1-0-0',
  schemaNudgeDesignVersion: 'generic-nudge',
  schemaFabOnlineDesignVersion: 'generic-fab-online',
  schemaFabOfflineDesignVersion: 'generic-fab-offline',
  typeFunction: 'function',
  typeLink: 'link'
}

export const GtmTrackers = {
  optimyClick : 'optimy_click',
  optimyJoinQueue: 'optimy_joinqueue',
  optimyLeftQueue: 'optimy_leftqueue',
  optimyBookingStarted: 'optimy_bookingstarted',
  optimyBookingCompleted: 'optimy_bookingcompleted',
  optimyLeaveMessageStarted: 'optimy_leavemessagestarted',
  optimyLeaveMessageCompleted: 'optimy_leavemessagecompleted',
  optimyCallStarted: 'optimy_callstarted',
  optimyCallJoined: 'optimy_calljoined',
  optimyCallLeft: 'optimy_callleft',
  optimyCallEnded: 'optimy_callended',
  optimyOutboundInitiated: 'optimy_outboundinitiated',
  optimyOutboundAccepted: 'optimy_outboundaccepted',
  optimyOutboundRejected: 'optimy_outboundrejected',
  optimyOutboundCancelled: 'optimy_outboundcancelled'
}
