export enum CallStatus {
    in_progress = 'In Progress',
    scheduled = 'Scheduled',
    pending = 'Pending',
    cancelled = 'Cancelled',
    ad_hoc = 'Ad Hoc',
    closed = 'Closed',
    missed = 'Missed',
    callEnded = 'callEnded',
    callCanceled = 'callCanceled',
    callStartShortly = 'callStartShortly',
    routing = 'Routing'
}

export const CallStatusWithInformation: CallStatusInfo[] = [{
    status: 'Pending',
    icon: 'call-not-started-icon',
    callStatus: 'callInFuture.callStartShortly',
    msg: 'inCall.callNotStartedMsg'
}, {
    status: 'Cancelled',
    icon: 'call-ended-icon',
    callStatus: 'inCall.callCanceled',
    msg: 'inCall.callCancelledOrEndedMsg'
}, {
    status: 'Ad Hoc',
    icon: 'call-not-started-icon',
    callStatus: 'callInFuture.callStartShortly',
    msg: 'inCall.callNotStartedMsg'
}, {
    status: 'Closed',
    icon: 'call-ended-icon',
    callStatus: 'inCall.callEnded',
    msg: 'inCall.callCancelledOrEndedMsg'
}, {
    status: 'Missed',
    icon: 'call-ended-icon',
    callStatus: 'inCall.callCanceled',
    msg: 'inCall.callCancelledOrEndedMsg'
}]

export const WaitingCallStatus: string[] = ['Pending', 'Ad Hoc', 'Scheduled', 'In Progress'];

export interface CallStatusInfo {
    status: string;
    icon: string;
    callStatus: string;
    msg: string;
}

export interface Queue {
    id: number;
    queue_id: number;
    guest_user_id: string;
    position_index: number;
    estimated_start: number;
    guest_link: string;
    agents_available: number;
    status?: string;
    created: Date | number | any;
    room_name: string;
}

export interface QueueAddRes {
    data: Queue;
    guest: CreateGuestRes;
    status: number
}

export interface CreateGuestRes {
    guesttoken: string;
    id: string;
    status?: number;
}

export interface CreateReq {
    user_agent: string;
    tenantid: number;
    full_name?: string | null;
    queue_id?: number;
    ip: string;
    email?: string;
    phone?: number | string;
    client_user_id?: string;
}

export interface NewChannelData {
    unique_name: string;
    members_count: number;
    date_updated: string;
    friendly_name: string;
    created_by: string;
    account_sid: string;
    date_created: string;
    sid: string;
    attributes: null;
    service_sid: string;
    type: string;
    messages_count: number;
}

export enum EventTypes {
    messageUpdated = 'onMessageUpdated',
    messageAdded = 'onMessageAdded'
}

export enum ScreenShareType {
    default = 'defaultAgentScreen',
    actual = 'actualAgentScreen'
}

export enum ButtonTexts {
    liveVideoText = 'Start Live Video',
    scheduleBookingText = 'Schedule An Online Session'
}

export interface DeviceList {
    deviceId: string;
    groupId: string;
    kind: string;
    label: string;
}

export enum DeviceTypes {
    audioInput = 'audioinput',
    audioOutput = 'audiooutput',
    videoInput = 'videoinput'
}

export enum IgnoreDeviceIdTypes {
    default = 'default',
    communications = 'communications'
}

export const GuestCreateDefaultFields: string[] = ['tenantid', 'client_user_id', 'email', 'phone', 'ip', 'user_agent'];

export enum MicCameraPermission {
    mic = 'mic',
    camera = 'camera'
}

export interface OutbounRejectReq {
    room_name: string;
    auto_reject: number;
}

export const ColorSet = [
    {
        bgColor: '#ff9a1e',
        textColor: '#000000'
    },
    {
        bgColor: '#00a5be',
        textColor: '#000000'
    },
    {
        bgColor: '#0352cc',
        textColor: '#ffffff'
    },
    {
        bgColor: '#df360e',
        textColor: '#ffffff'
    },
    {
        bgColor: '#5345ab',
        textColor: '#ffffff'
    },
    {
        bgColor: '#ffeb3b',
        textColor: '#000000'
    },
    {
        bgColor: '#4caf50',
        textColor: '#000000'
    },
    {
        bgColor: '#03a9f4',
        textColor: '#000000'
    },
    {
        bgColor: '#795548',
        textColor: '#ffffff'
    },
    {
        bgColor: '#607d8b',
        textColor: '#ffffff'
    }];

export interface DeviceStatus {
    cameraUpdated?: boolean;
    micUpdated?: boolean;
    switchCamUpdated?: boolean;
    chatUpdated?: boolean
}

export interface PermissionError {
    camError: boolean;
    micError: boolean;
}

export enum TimeExceedAction {
    leaveMessage = 'leave_message',
    booking = 'booking',
    skip = 'skip',
    close = 'close'
}

export interface FabButtonType {
    icon: string;
    action: string;
    text: string;
}

export const FabButtons = [
    {
        text: 'Leave Message',
        action: 'leave_message',
        icon: 'email'
    },
    {
        text: 'Schedule Call',
        action: 'booking',
        icon: 'schedule-live-session-calendar'
    },
    {
        text: 'Leave',
        action: 'leave',
        icon: 'queue-leave'
    }
];

export interface JoinQueueInterface {
    queue_wait_questions?: QueueBubble[];
    queue_interrupt_1: QueueBubble;
    queue_interrupt_2: QueueBubble;
    queue_thank?: QueueBubble;
    queue_agent_join: QueueBubble;
    queue_join: QueueBubble;
}

export interface QueueBubble {
    text?: LangText;
    title?: LangText;
    appear_delay?: number;
    choices?: BubbleChoice[];
    event_key: string;
}

// TODO: remove the bubble choice interface, need to use CustomButton instead
export interface BubbleChoice {
    text: LangText;
    type: string;
    action?: TimeExceedAction;
    skip_to_question?: number;
    queue_id?: number;
    skip_to_question_fail?: number;
    url?: string;
}

export interface LangText {
    [key: string]: string;
}

export enum JoinQueueBubbleActions {
    queueWaitQuestions = 'queue_wait_questions',
    queueInterrupt1 = 'queue_interrupt_1',
    queueInterrupt2 = 'queue_interrupt_2',
    queueThank = 'queue_thank',
    queueAgentJoin = 'queue_agent_join',
    queueJoin = 'queue_join'
}

export interface FabClickState {
    clickTime: number;
    isOpen: boolean;
}

export interface TimePauseObj {
    timeDiffTotal: number;
    startTime: number;
}

export const CallButtons = [
    {
        text: 'video',
        action: 'video',
        icon: 'video'
    },
    {
        text: 'Invite',
        action: 'invite',
        icon: 'people-invite'
    },
    {
        text: 'Leave',
        action: 'leave',
        icon: 'phone-slash'
    }
];

export enum CallStates {
    minimize = 'minimize',
    maximize = 'maximize',
    default = 'default',
    maximizeFull = 'maximizeFull'
}
