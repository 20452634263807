import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {
  url: string;

  constructor(
      private httpClient: HttpClient
  ) {
    this.url = environment.baseApiUrl
  }
  public get(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
    if (!reqOpts) {
      reqOpts = { params: new HttpParams() };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (params.hasOwnProperty(k)) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }

    return this.httpClient.get(`${this.url}/${endpoint}`, reqOpts);
  }

  public post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.httpClient.post(
        `${this.url}/${endpoint}`,
        body,
        reqOpts
    );
  }

  public put(endpoint: string, params: any, body: any, reqOpts?: any): Observable<any> {
    if (!reqOpts) {
      reqOpts = { params: new HttpParams() };
    }
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (params.hasOwnProperty(k)) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }
    return this.httpClient.put(`${this.url}/${endpoint}`, body, reqOpts);
  }

  public delete(endpoint: string, reqOpts?: any): Observable<any> {
    return this.httpClient.delete(`${this.url}/${endpoint}`, reqOpts);
  }

  public patch(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.httpClient.patch(
        `${this.url}/${endpoint}`,
        body,
        reqOpts
    );
  }
  public getExternal(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
    if (!reqOpts) {
      reqOpts = { params: new HttpParams() };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (params.hasOwnProperty(k)) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }

    return this.httpClient.get(`${endpoint}`, reqOpts);
  }
  public postExternal(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.httpClient.post(
        endpoint,
        body,
        reqOpts
    );
  }
}
